<template>
    <CustomLoader v-if="loading" />

    <template v-else>
        <div class="card mb-5 mb-xxl-8 py-0 bank-settings">
            <div class="card-body pt-6 px-0 pb-0">
                <div class="d-flex flex-wrap flex-sm-nowrap mb-5 settings-section">
                    <div class="me-sm-7 mb-4 d-flex justify-content-center justify-content-sm-start">
                        <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                            <img :src="bankDetails.bankInfo.icon_path" alt="image" />
                        </div>
                    </div>

                    <div class="flex-grow-1">
                        <div
                            class="d-flex justify-content-between align-items-start flex-wrap mb-2 flex-column flex-sm-row align-items-center">
                            <div class="d-flex flex-column">
                                <div class="d-flex align-items-center mb-2">
                                    <a href="#"
                                       class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{{ bankDetails.bankInfo.name
                                        }}</a>
                                </div>
                            </div>

                            <div class="d-flex align-items-center">
                                <!--                <router-link to="/bank/settings/bank-settings/settings" class="btn main-btn me-3 px-10">-->
                                <!--                  <img height="15" width="14" class="me-3" src="/media/buying/icons/edit-white.svg" alt="">-->
                                <!--                  {{ $t("Edit profile") }}-->
                                <!--                </router-link>-->
                                <template
                                    v-if="isUserManagement && (
                                    userInfo.can(BankUserPermissionTypeEnum.INVITE_USER) ||
                                    userInfo.can(BankUserPermissionTypeEnum.INVITE_ADMIN)
                                  )"
                                >
                                    <a @click.prevent="openInvitedModal" class="see-all-invited-btn">
                                        {{ $t("See all Invited") }}
                                    </a>
                                    <div class="btn main-btn" @click="addNewUserModal = true">
                                        {{ $t("Add New User") }}
                                    </div>
                                </template>
                                <!--                <div class="me-0">-->
                                <!--                  <el-dropdown trigger="click" placement="bottom-end">-->
                                <!--                    <span class="el-dropdown-link">-->
                                <!--                      <button class="btn btn-sm btn-icon btn-bg-transparent btn-active-color-primary">-->
                                <!--                        <i class="bi bi-three-dots fs-1 fw-bolder"></i>-->
                                <!--                      </button>-->
                                <!--                    </span>-->
                                <!--                    <template #dropdown>-->
                                <!--                      <el-dropdown-menu>-->
                                <!--                        <el-dropdown-item>{{$t('Contact Support')}}</el-dropdown-item>-->
                                <!--                        <el-dropdown-item>{{$t('GDPR export')}}</el-dropdown-item>-->
                                <!--                        <el-dropdown-item>{{$t('View privacy policy')}}</el-dropdown-item>-->
                                <!--                      </el-dropdown-menu>-->
                                <!--                    </template>-->
                                <!--                  </el-dropdown>-->
                                <!--                </div>-->
                            </div>
                        </div>

                        <div class="d-flex flex-wrap flex-stack mt-md-8">
                            <div class="d-flex flex-column flex-grow-1">
                                <div class="d-flex flex-wrap user-counter">
                                    <template v-for="(item, index) in counter">
                                        <div
                                            :class="`border border-gray-300 rounded min-w-175px py-3 px-4 mb-3 ${item.statusClass} ${(index+1) !== counter.length ? 'me-6' : ''}`">
                                            <div class="d-flex flex-column align-items-center">
                                                <div class="fw-bold fs-6 text-gray-400">{{ $t(item.label) }}</div>
                                                <div class="fs-1 fw-boldest">{{ item.value }}</div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex overflow-auto h-55px">
                    <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                        <template v-for="(item, index) in navLinks">
                            <li class="nav-item" :key="index" v-if="item.visible">
                                <router-link
                                    :to="item.link"
                                    class="nav-link text-active-primary mx-8 fs-4"
                                    active-class="active"
                                >{{ $t(item.label) }}</router-link>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>

        <router-view :bankDetails="bankDetails"></router-view>

        <!-- Add new user drawer -->
        <AddUserDrawer
            v-model="addNewUserModal"
            :bankDetails="bankDetails"
            @handleClose="addNewUserModal=false"
        />

        <!-- See all Invited drawer -->
        <SeeAllBankInvitedDrawer
            v-if="seeAllInvitedModal"
            v-model="seeAllInvitedModal"
            :invited-data="bankDetails.invitedUsers"
            :loading="loadingInvited"
            :bankDetails="bankDetails"
            @handleClose="seeAllInvitedModal=false"
            @addNewUser="seeAllInvitedModal=false;addNewUserModal=true;"
        />

    </template>
</template>

<script>
import store from "@/store";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { BankSettingsContainer } from "@/store/models/bank/BankSettingsContainer";
import AddUserDrawer from "@/buying-teams/pages/bank/teams/components/AddUserDrawer";
import { getGapStatusClass } from "@/core/helpers/GlobalHelper";
import SeeAllBankInvitedDrawer from "@/buying-teams/pages/bank/teams/components/SeeAllBankInvitedDrawer";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";

export default {
    name: "BankSettings",
    components: {
        SeeAllBankInvitedDrawer,
        AddUserDrawer,
        CustomLoader
    },
    data() {
        return {
            BankUserPermissionTypeEnum,
            loading: true,
            bankDetails: null,
            addNewUserModal: false,
            seeAllInvitedModal: false,
            loadingInvited: false
        };
    },

    computed: {
        isUserManagement() {
            return this.$route.path === "/bank/settings/bank-settings/user-management";
        },
        bankData() {
            return store.getters.bank;
        },
        userInfo() {
            return store.getters.currentUser;
        },
        counter() {
            return [
                {
                    label: "Average Score",
                    value: this.bankDetails ? this.bankDetails.stats.score : 0,
                    statusClass: "average_score"
                },
                {
                    label: "Average Importance",
                    value: this.bankDetails ? this.bankDetails.stats.importance : 0,
                    statusClass: ""
                },
                {
                    label: "Average Gap",
                    value: this.bankDetails ? this.bankDetails.stats.gap : 0,
                    statusClass: getGapStatusClass(this.bankDetails ? this.bankDetails.stats.gap : 0)
                }
            ];
        },
        navLinks() {
            const menuLinks = [
                {
                    label: "Overview",
                    link: "/bank/settings/bank-settings/overview",
                    visible: true,
                },
                {
                    label: "Countries",
                    link: "/bank/settings/bank-settings/countries",
                    visible: true,
                },
                {
                    label: "Product Areas",
                    link: "/bank/settings/bank-settings/product-areas",
                    visible: true,
                },
                {
                    label: "User Management",
                    link: "/bank/settings/bank-settings/user-management",
                    visible: true,
                },
                {
                    label: "Permission Groups",
                    link: "/bank/settings/bank-settings/permission-groups",
                    visible: this.userInfo.can(BankUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS)
                },
                {
                    label: "Bank Settings",
                    link: "/bank/settings/bank-settings/settings",
                    visible: this.userInfo.can(BankUserPermissionTypeEnum.EDIT_BANK_SETTINGS)
                },
                // {
                //   label: 'Sharing Policy',
                //   link: '/bank/settings/bank-settings/sharing-policy'
                // },
            ];
            return menuLinks;
        }
    },

    async mounted() {
        setCurrentPageBreadcrumbs({
            title: this.bankData.name,
            logo: this.bankData.icon_path
        });

        this.loading = true;
        store.dispatch("fetchBankDetails")
            .then(async (res) => {
                await store.dispatch("getBankSettings");
                this.bankDetails = new BankSettingsContainer({
                    bankInfo: this.bankData,
                    ...res
                });
            }).finally(() => {
            this.loading = false;
        });
    },

    methods: {
        openInvitedModal() {
            this.seeAllInvitedModal = true;
            this.loadingInvited = true;
            store.dispatch("fetchBankDetails")
                .then(async (res) => {
                    this.bankDetails.setInvitedUsers(res.invited);
                })
                .finally(() => {
                    this.loadingInvited = false;
                });
        }
    }
};
</script>

<style scoped lang="scss">
.nav-stretch .nav-item {
    &:first-child a {
        margin-left: 18px !important;
    }
}

.user-counter {
    .border {
        &.status-danger {
            border-color: #E22D21 !important;

            .fw-boldest {
                color: #E22D21;
            }
        }

        &.status-warning {
            border-color: #FAD03B !important;

            .fw-boldest {
                color: #FAD03B;
            }
        }

        &.status-success {
            border-color: #2BC490 !important;

            .fw-boldest {
                color: #2BC490;
            }
        }
    }
}

.bank-settings {
    .nav-line-tabs .nav-item .nav-link {
        white-space: pre;
    }

    .user-counter {
        .average_score {
            position: relative;
            margin-right: 39px !important;

            &:before {
                content: "";
                position: absolute;
                width: 1px;
                height: 100%;
                background: #000000;
                top: 0;
                right: -20px;
                opacity: 0.1;
            }
        }
    }

    .see-all-invited-btn {
        margin-right: 22px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #435BF4;
        cursor: pointer;
        transition: .2s ease-in-out;

        &:hover {
            opacity: 0.7;
        }
    }

    @media (max-width: 685px) {
        .settings-section .user-counter {
            margin-top: 0;
        }
    }
    @media (max-width: 576px) {
        .user-counter > div.average_score,
        .user-counter > div {
            margin-right: 0 !important;
            width: 100%;

            &:before {
                display: none;
            }
        }
    }
}
</style>
