<template>
  <div
    class="customer-invite-list-item"
    :class="{
      'is-bounced': item.status_id === InviteStatusEnum.BOUNCED,
      'loading-operation': loadingOperation
    }"
  >
    <div class="is-loading" v-if="loading">{{ $t('Loading') }}...</div>
    <div class="info">
      <div>
        <div class="name">{{ item.email }}</div>
        <span class="email" v-if="item.date">
          {{$t("Sent on")}} <b>{{ item.date }}</b>
        </span>
      </div>
      <div class="info__left">
        <div class="user-role">
          {{ item.role.text }}
        </div>
        <div class="status-wrapper">
          <span class="invite-status" :class="item.status.className">
          {{  item.status.text }}
        </span>
        </div>
<!--        <button-->
<!--          class="refresh-btn"-->
<!--          :disabled="item.status_id === InviteStatusEnum.ACCEPTED || loading"-->
<!--          @click="$emit('refreshInvitation', item)"-->
<!--        >-->
<!--          <img src="/media/buying/icons/refresh-icon.svg" alt="">-->
<!--        </button>-->

        <button
          class="refresh-btn"
          :disabled="!item.id || loadingOperation"
          @click="$emit('deleteInvitation', item)"
        >
          <img src="/media/buying/icons/delete-icon.svg" alt="">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {InviteStatusEnum} from "@/store/models/bank/BankSettingsContainer";

export default {
  name: "InviteListItem",
  props: {
    item: Object,
    loadingOperation: {
      type: Boolean,
      default: false
    }
  },
  emits: ['refreshInvitation', 'deleteInvitation'],
  data() {
    return {
      loading: false,
      InviteStatusEnum
    }
  },
  methods: {
    refreshInvitation() {
      this.loading = true;
    }
  }
};
</script>

<style scoped lang="scss">
.customer-invite-list-item {
  padding-bottom: 16px;
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  &.loading-operation {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #ffffff54;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(2px);
      z-index: 8;
    }
  }
  &:not(:last-child) {
    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      right: 40px;
      left: 40px;
      height: 2px;
      background: rgba(0, 0, 0, 0.06);
    }
  }
  .is-loading {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff6;
    backdrop-filter: blur(3px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #8B8B8B;
  }
  &.is-bounced {
    background: #FDF7F7;
  }
  .refresh-btn {
    background: transparent;
    border: none;
    padding: 0;
    border-radius: 5px;
    transition: .3s;
    img {
      transition: .3s;
    }
    &:disabled {
      opacity: 0.4;
      &:hover img {
        transform: none;
      }
    }
    &:hover {
      background: #0000000d;
      img {
        opacity: 0.8;
      }
    }
  }
  .name {
    font-weight: 700;
    font-size: 18px;
    color: #4F4F4F;
    margin-bottom: 12px;
  }
.info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .email {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #8B8B8B;
  }
  .date{
    font-weight: 300;
    font-size: 12px;
    color: #4F4F4F;
    width: 30%;

  }
  .status {
    font-weight: 300;
    font-size: 12px;
    color: #4F4F4F;
    margin-right: 8px;
    width: 30%;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 22px;

    .user-role {
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      color: #4F4F4F;
    }
  }

  .invite-status {
    font-weight: 800;
    padding: 6px;
    min-width: 84px;
    display: inline-block;
    height: 30px;
    font-size: 14.2326px;
    line-height: 17px;
    border-radius: 2.37209px;
    text-align: center;

    &-accepted {
      color: #0A882D;
      background-color: rgba(95, 251, 40, 0.14);
    }
    &-pending {
      color: #E6B25F;
      background: #FEF9E7;
    }
    &-bounced {
      color: #ED7B74;
      background: #FDF0F0;
    }
  }
}
}
@media (max-width: 992px) {
  .customer-invite-list-item {
    padding-left: 20px;
    padding-right: 20px;
    &:not(:last-child):after {
      left: 0;
    }
    .info .email {
      width: 100%;
    }
  }
}
@media (max-width: 560px){
  .customer-invite-list-item {
    .name {
      font-size: 14px;
      margin-bottom: 5px;
    }
    .info{
      flex-direction: column;
      .email {
        font-size: 14px;
      }
      .email, .status-wrapper{
        margin: 12px 0;
      }
    }
    text-align: center;
  }
}
</style>
